<template>
  <div class="row">
    <div class="col-md-3">
      <div class="widget widget-tile">
        <div class="data-info">
          <div class="desc">Partners</div>
          <div class="value">
            <span class="number" data-toggle="counter" data-end="113">
              {{ partnerCount }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="widget widget-tile">
        <div class="chart sparkline" id="spark2"></div>
        <div class="data-info">
          <div class="desc">Routers</div>
          <div class="value">
            <span
                class="number"
                data-toggle="counter"
                data-end="80"
                data-suffix="%"
            >
              {{ routersCount }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="widget widget-tile">
        <div class="chart sparkline" id="spark3"></div>
        <div class="data-info">
          <div class="desc">Kiosks</div>
          <div class="value">
            <span class="number" data-toggle="counter" data-end="532">
              {{ kioskCount }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="widget widget-tile">
        <div class="chart sparkline" id="spark4"></div>
        <div class="data-info">
          <div class="desc">Open Tickets</div>
          <div class="value">
           <span class="number" data-toggle="counter" data-end="113">
             {{ open_tickets_count }}
           </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AnalyticsCard",
  data() {
    return {
      partnerCount: 0,
      kioskCount: 0,
      routersCount: 0,
      open_tickets_count: 0,
    };
  },
  mounted() {
    this.fetchAnalytics();
  },
  methods: {
    fetchAnalytics() {
      axios
          .get("v1/analytics/")
          .then((response) => {
            if (response.status === 200) {
              this.partnerCount = response.data.partners_count;
              this.kioskCount = response.data.kiosks_count;
              this.routersCount = response.data.routers_count;
              this.open_tickets_count = response.data.open_tickets_count;
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$swal("Error", `${error.response.data.detail}`, "error");
            }
          });
    },
  },
};
</script>
