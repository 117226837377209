<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-table">
        <div class="card-header">
          Recently Added Partners
        </div>
        <div class="card-body">
          <table
            class="table table-striped table-hover table-fw-widget"
            id="table5"
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Country</th>
                <th>Admin Email</th>
                <th>Plan</th>
                <th>Added</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="partner in partners"
                :key="partner.id"
                class="odd gradeX"
              >
                <td>{{ partner.name }}</td>
                <td>{{ partner.country }}</td>
                <td>{{ partner.admin_email }}</td>
                <td>{{ partner.plan.name | planNameDisplay }}</td>
                <td>
                  {{ new Date(partner.created).toLocaleString() }}
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'partner-details',
                      params: { id: partner.id },
                    }"
                  >
                    <button class="btn btn-primary">View</button>
                  </router-link>
                </td>
                <td class="center"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PartnersTable",
  data() {
    return {
      partners: "",
      error: "",
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    getPartners() {
      axios
        .get(`v1/partners/`)
        .then((response) => {
          if (response.status === 200) {
            this.partners = response.data.splice(0, 5);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
  },
};
</script>
