<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">Home</li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'dashboard' }"
              >Dashboard</router-link-active
            >
          </li>
        </ol>
      </nav>
    </div>
    <AnalyticsCard />

    <TicketTable
      v-if="
        loggedInUser.role === adminRole ||
        loggedInUser.user_type === customerSupportUserType
      "
    />

    <PartnersTable />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AnalyticsCard from "@/components/Home/AnalyticsCards";
import PartnersTable from "@/components/Home/PartnersTable";
import TicketTable from "@/components/Home/TicketTable";

export default {
  components: {
    AnalyticsCard,
    PartnersTable,
    TicketTable,
  },
  data: () => ({
    adminRole: "ared_admin",
    customerSupportUserType: "Customer Support Agent",
  }),
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
};
</script>
