<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-table">
        <div class="card-header">
          Open Tickets
        </div>
        <div class="card-body">
          <table
            class="table table-striped table-hover table-fw-widget"
            id="table5"
          >
            <thead>
              <tr>
                <th>Ticket ID</th>
                <!-- <th>Partner</th> -->
                <th>Subject</th>
                <th>Department</th>
                <th>Priority</th>
                <th>Added</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="tickets.length > 0">
              <tr v-for="ticket in tickets" :key="ticket.id" class="odd gradeX">
                <td>{{ ticket.id }}</td>
                <!-- <td>{{ kiosk.partner.name }}</td> -->
                <td>
                 {{ ticket.subject }}
                </td>
                <td>
                 {{ ticket.department }}
                </td>
                <td>
                 {{ ticket.priority }}
                </td>
                <td>
                  {{ new Date(ticket.created).toLocaleString() }}
                </td>
                <td>
                  <router-link
                    :to="{ name: 'ticket-details', params: { id: ticket.id } }"
                  >
                    <button class="btn btn-primary">View</button>
                  </router-link>
                </td>
                <td class="center"></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="odd gradeX">
                <td class="text-center" colspan="6">
                  <span class="text-success">No open tickets at the moment</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TicketTable",
  data() {
    return {
      tickets: [],
      error: "",
    };
  },
  mounted() {
    this.getTickets();
  },
  methods: {
    async getTickets() {
      await axios
        .get(`v1/tickets/?is_open=true`)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data)
            this.tickets = response.data.splice(0,9);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },

  },
};
</script>